import React, { Fragment }  from 'react'
import { Route, Switch } from 'react-router-dom'
import MenuPage from '../../../pages/MenuPage'
import PenggunaPage from '../../../pages/ModulPengguna/Pengguna'
import PenggunaBaruPage from '../../../pages/ModulPengguna/PenggunaBaru';
import UbahPenggunaPage from '../../../pages/ModulPengguna/UbahPengguna';
import DistributorPage from '../../../pages/ModulDistributor/Distributor';
import DistributorBaru from '../../../pages/ModulDistributor/DistributorBaru';
import UbahDistributor from '../../../pages/ModulDistributor/UbahDistributor';
import PelangganPage from '../../../pages/ModulPelanggan/Pelanggan';
import PelangganBaru from '../../../pages/ModulPelanggan/PelangganBaru';
import UbahPelanggan from '../../../pages/ModulPelanggan/UbahPelanggan';
import UbahSatuan from '../../../pages/ModulSatuan/UbahSatuan';
import SatuanBaru from '../../../pages/ModulSatuan/SatuanBaru';
import SatuanPage from '../../../pages/ModulSatuan/Satuan';
import BarangPage from '../../../pages/ModulBarang/Barang';
import BarangBaru from '../../../pages/ModulBarang/BarangBaru';
import UbahBarang from '../../../pages/ModulBarang/UbahBarang';
import UbahSaldoAwal from '../../../pages/ModulBarang/UbahSaldoAwal';
import UbahHargaBarang from '../../../pages/ModulHargaBarang/UbahHargaBarang';
import HargaBarangPage from '../../../pages/ModulHargaBarang/HargaBarang';
import BarangDariExcel from '../../../pages/ModulBarang/BarangDariExcel';
import BarangBaruExcel from '../../../pages/ModulBarang/BarangBaruExcel';
import KonversiPage from '../../../pages/ModulKonversi/Konversi';
import PembelianPage from '../../../pages/ModulPembelian/PembelianPage';
import SemuaStokBarang from '../../../pages/ModulStokBarang/SemuaStokBarang';
import RincianStokBarang from '../../../pages/ModulStokBarang/RincianStokBarang';
import PPNPage from '../../../pages/ModulPPN/PPNPage';
import StokReturPage from '../../../pages/ModulStokRetur/StokReturPage';
import PenjualanPage from '../../../pages/ModulPenjualan/PenjualanPage';
import LaporanPage from '../../../pages/ModulLaporan/LaporanPage';
import LaporanPenjualanPage from '../../../pages/ModulLaporan/LaporanPenjualanPage';
import LaporanPembelianPage from '../../../pages/ModulLaporan/LaporanPembelianPage';
import RincianPembelian from '../../../pages/ModulLaporan/RincianPembelian';
import LaporanReturPembelianPage from '../../../pages/ModulLaporan/LaporanReturPembelianPage';
import RincianReturPembelian from '../../../pages/ModulLaporan/RincianReturPembelian';
import LaporanPelangganPage from '../../../pages/ModulLaporan/LaporanPelangganPage';
import LaporanPenjualDetails from '../../../pages/ModulLaporan/LaporanPenjualanDetails';
import ChoosePrint from '../../../pages/ModulLaporan/ChoosePrint';
import LaporanReturPenjualan from '../../../pages/ModulLaporan/LaporanReturPenjualan';
import RincianReturPenjualan from '../../../pages/ModulLaporan/RincianReturPenjualan';
import LaporanDistributorPage from '../../../pages/ModulLaporan/LaporanDistributorPage';
import RincianPembelianDistributor from '../../../pages/ModulLaporan/RincianPembelianDistributor';
import StrukPerPelanggan from '../../../pages/ModulLaporan/StrukPerPelanggan';
import PerubahanPPN from '../../../pages/ModulBarang/PerubahanPPN';
import { useSelector } from 'react-redux';
import { Backdrop, Box, LinearProgress, Typography } from '@mui/material';
import LaporanPembelianLengkap from '../../../pages/ModulLaporan/LaporanPembelianLengkapPage/';

const Content = () => {
    const {statusServer} = useSelector(state=>state.homeReducers);
    return (
        <Fragment>
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={statusServer === false ? true : false}
            >
            <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
            <Typography>Connecting to server</Typography>
            <LinearProgress/>
            </Box>
        </Backdrop>
        <Switch>
            <Route exact path='/pengguna/ubah/:idpengguna'>
                <UbahPenggunaPage/>
            </Route>
            <Route exact path='/pengguna/baru'>
                <PenggunaBaruPage/>
            </Route>
            <Route path='/pengguna'>
                <PenggunaPage/>
            </Route>
            <Route exact path='/distributor/ubah/:iddistributor'>
                <UbahDistributor/>
            </Route>
            <Route exact path='/distributor/baru'>
                <DistributorBaru/>
            </Route>
            <Route path='/distributor'>
                <DistributorPage/>
            </Route>
            <Route exact path='/pelanggan/ubah/:idpelanggan'>
                <UbahPelanggan/>
            </Route>
            <Route exact path='/pelanggan/baru'>
                <PelangganBaru/>
            </Route>
            <Route path='/pelanggan'>
                <PelangganPage/>
            </Route>
            <Route path='/barang/konversi/:idbarang/:namabarang'>
                <KonversiPage/>
            </Route>
            <Route exact path='/satuan/ubah/:idsatuan'>
                <UbahSatuan/>
            </Route>
            <Route exact path='/satuan/baru'>
                <SatuanBaru/>
            </Route>
            <Route exact path='/satuan'>
                <SatuanPage/>
            </Route>
            <Route exact path='/barang/rincian/:idbarang'>
                <UbahSaldoAwal/>
            </Route>
            <Route exact path='/barang/ubah/:idbarang'>
                <UbahBarang/>
            </Route>
            <Route exact path='/barang/baru/excel/:id/:nama'>
                <BarangBaruExcel/>
            </Route>
            <Route exact path='/barang/excel'>
                <BarangDariExcel/>
            </Route>
            <Route exact path='/barang/baru'>
                <BarangBaru/>
            </Route>
            <Route exact path='/barang/perubahanppn'>
                <PerubahanPPN/>
            </Route>
            <Route path='/barang'>
                <BarangPage/>
            </Route>
            <Route exact path='/laporan/pembelian'>
                <LaporanPembelianPage/>
            </Route>
            <Route exact path='/laporan/penjualan'>
                <LaporanPenjualanPage/>
            </Route>
            <Route exact path='/laporan/distributor'>
                <LaporanDistributorPage/>
            </Route>
            <Route exact path='/laporan/pelanggan'>
                <LaporanPelangganPage/>
            </Route>
            <Route exact path='/laporan/returpembelian'>
                <LaporanReturPembelianPage/>
            </Route>
            <Route exact path='/laporan/returpenjualan'>
                <LaporanReturPenjualan/>
            </Route>
            <Route exact path='/laporan'>
                <LaporanPage/>
            </Route>
            <Route exact path='/laporan/penjualan/:fakturpenjualan'>
                <ChoosePrint/>
            </Route>
            <Route exact path='/laporan/penjualanpelanggan/:fakturpenjualan'>
                <StrukPerPelanggan/>
            </Route>
            <Route exact path='/laporan/penjualan/:ppn/:fakturpenjualan'>
                <LaporanPenjualDetails/>
            </Route>
            <Route exact path='/laporan/pembelian/:fakturpembelian'>
                <RincianPembelian/>
            </Route>
            <Route exact path='/laporan/pembelianlengkap/:fakturpembelian'>
                <LaporanPembelianLengkap/>
            </Route>
            <Route exact path='/laporan/pembeliandistributor/:fakturpembelian'>
                <RincianPembelianDistributor/>
            </Route>
            <Route exact path='/laporan/returpembelian/:fakturretur'>
                <RincianReturPembelian/>
            </Route>
            <Route exact path='/laporan/returpenjualan/:fakturpenjualan'>
                <RincianReturPenjualan/>
            </Route>
            <Route exact path='/stokbarang'>
                <SemuaStokBarang/>
            </Route>
            <Route exact path='/ppn'>
                <PPNPage/>
            </Route>
            <Route exact path='/stokretur'>
                <StokReturPage/>
            </Route>
            <Route exact path='/stokbarang/rincian'>
                <RincianStokBarang/>
            </Route>
            <Route exact path='/menu'>
                <RincianStokBarang/>
            </Route>
            <Route path='/hargabarang/ubah'>
                <UbahHargaBarang/>
            </Route>
            <Route path='/hargabarang'>
                <HargaBarangPage/>
            </Route>
            <Route path='/pembelian'>
                <PembelianPage/>
            </Route>
            <Route path='/penjualan'>
                <PenjualanPage/>
            </Route>

            <Route path='/'>
                <MenuPage/>
            </Route>
        </Switch>
        
        </Fragment>
    )
}

export default Content
