import React from 'react'
import {createBrowserHistory} from 'history';
import {ProtectedRoutes} from '..';
import { Route, Router ,Switch} from 'react-router-dom';
import Login from '../../pages/ModulPengguna/Login';
import Home from '../../pages/Home';
import SignUp from '../../pages/ModulPengguna/SignUp';
import MenuRestoPage from '../../pages/MenuRestoPage';


const Routes = () => {
    const history = createBrowserHistory();
    return (
        <Router basename='/v1' history={history}>
            <Switch>
                <Route path='/login' component={Login}/>
                <Route path='/SignUp' component={SignUp}/>
                <Route path='/Menu' component={MenuRestoPage}/>
                <ProtectedRoutes path='/' component={Home}/>
            </Switch>    
        </Router>
    )
}


export default Routes
