import React, { useState } from 'react'
import { MenuMakanan, MenuMinuman, MenuPaketNasi } from '../../components/assets'
import { Box, Container, Fab, Typography, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const MenuRestoPage = () => {
  const theme = useTheme();
  const boxWapper ={
    display:'flex',
    // justifyContent:'center',
    // alignItems:'center',
    border:'1px solid black',
    height:'100vh',
    width:'100vw',
    [theme.breakpoints.up('sm')]:{
        maxWidth:"397px",
        maxHeight:'561px',
        // borderRadius:'10px',
    },
    [theme.breakpoints.down('sm')]:{
        maxWidth:"387px",
        maxHeight:'551px',
        // borderRadius:'10px',
    }
  }
  const opersionalTimeWapper={
    width:'100%',
    display:'flex',
    justifyContent:'space-between',
    [theme.breakpoints.up('sm')]:{
      maxWidth:"397px",
      // borderRadius:'10px',
    },
    [theme.breakpoints.down('sm')]:{
        maxWidth:"387px",
        // borderRadius:'10px',
    }
  }
  const [id,setId]=useState("MenuMakanan");
  
  const handleClickMenuMakanan = () =>{
    setId("MenuMakanan");
  }
  const handleClickMenuPaketNasi = () => {
    setId("MenuPaketNasi")
  }
  const handleClickMenuMinuman = () =>{
    setId("MenuMinuman");
  }
  const menuMakanan = (
    <div>
      <Box sx={boxWapper}>
            <img 
            width="100%"
            height="100%" 
            src={MenuMakanan} 
            alt={"menumakanan"} 
            onClick={()=>handleClickMenuPaketNasi()}/>
      </Box>
      <Fab sx={{position: 'fixed',bottom: theme.spacing('10%'),right: theme.spacing(1)}} size="small" color="primary" aria-label="add" 
      onClick={()=>handleClickMenuPaketNasi()}
      >
          <ArrowForwardIosIcon/>
      </Fab>
    </div>
  )
  const menuPaketNasi = (
    <div>
      <Box sx={boxWapper}>
            <img 
            width="100%"
            height="100%"
            src={MenuPaketNasi} 
            alt={"menupaketnasi"} 
            // onClick={(e)=>handleClickImage(e)}
            />
      </Box>
      <Fab sx={{position: 'fixed',bottom: theme.spacing('10%'),left: theme.spacing(1)}} size="small" color="primary" aria-label="add" 
      onClick={()=>handleClickMenuMakanan()}
      >
          <ArrowBackIcon/>
      </Fab>
      <Fab sx={{position: 'fixed',bottom: theme.spacing('10%'),right: theme.spacing(1)}} size="small" color="primary" aria-label="add" 
      onClick={()=>handleClickMenuMinuman()}
      >
          <ArrowForwardIosIcon/>
      </Fab>
    </div>
  )
  const menuMinuman = (
    <div>
      <Box sx={boxWapper}>
            <img 
            width="100%"
            height="100%"
            src={MenuMinuman} 
            alt={"menuMinuman"} 
            // onClick={(e)=>handleClickImage(e)}
            />
      </Box>
      <Fab sx={{position: 'fixed',bottom: theme.spacing('10%'),right: theme.spacing(1)}} size="small" color="primary" aria-label="add" 
      onClick={()=>handleClickMenuPaketNasi()}
      >
          <ArrowBackIcon/>
      </Fab>
    </div>
  )
  return (
    <Container maxWidth='xl' sx={{display:'flex',flexDirection:'column',height:'100vh',maxHeight:'100%',alignItems:'center',justifyContent:'center'}}>
      {id==="MenuMakanan" ? menuMakanan : id==="MenuPaketNasi" ? menuPaketNasi : menuMinuman}
      <Box sx={opersionalTimeWapper}>
          <div style={{display:'flex',flexDirection:'column',justifyContent:'flex-start'}}>
            <Typography fontSize="10px">Jam Operasional :</Typography>
            <Typography fontSize="10px">Senin-Jumat : 09:00 - 20:00</Typography>
          </div>
          <div style={{display:'flex',flexDirection:'column',justifyContent:'flex-start'}}>
            <Typography fontSize="10px">Sabtu : 09:00 - 21:00</Typography>
            <Typography fontSize="10px">Minggu : 09:00 - 17:00</Typography>
          </div>
      </Box>
    </Container>
  )
}

export default MenuRestoPage